<template>
  <hb-basic-page :title="$t('dashboard.title')"
                 :loading="loading"
                 :subtle-loading="loadingEvents"
                 :fill-height="loading">

    <v-col cols="12" md="8" offset-md="2" xl="6" offset-xl="3">
      <v-row style="{width: 100%}">
<!--        <Mentionable :keys="['@']"-->
<!--                     :items="mentionableItems"-->
<!--                     offset="-10"-->
<!--                     style="{width: 100%}"-->
<!--        >-->
          <v-textarea
            :placeholder="$t('dashboard.write_comment')"
            v-model="comment"
            rows="1"
            class="mx-3"
            style="{width: 100% !important;}"
          />
          <template #item-@="{ item }">
            <v-list-item style="background-color: white; transform: translateX(100px); padding: 4px 10px; border-radius: 4px; font-family: urw-form,sans-serif;">
              {{ item.label }}
            </v-list-item>
          </template>
<!--        </Mentionable>-->
      <v-btn
        @click="createDashboardComment"
        icon>
        <v-icon>mdi mdi-send</v-icon>
      </v-btn>
      </v-row>
    </v-col>

    <v-col cols="12" md="8" offset-md="2" xl="6" offset-xl="3">
      <v-row>
        <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 2">
          <v-switch
            :label="$t('dashboard.mentions')"
            v-model="mentions"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 5">
          <v-menu
            ref="menu1"
            v-model="dateRangeMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeFormatted"
                :label="$t('dashboard.date_range')"
                readonly
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              show-week
              full-width
              range
              v-model="dateRange"
              :scrollable="$vuetify.breakpoint.mdAndUp"
              @change="dateRangeChange"
              @close="dateRangeOnClose"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 5">
          <v-select
            v-model="eventTypeFilter"
            :items="eventTypes"
            :placeholder="`${$t('dashboard.filtered')} 0 ${$t('dashboard.selections')}`"
            item-text="desc"
            item-value="value"
            multiple
            hide-details
            single-line
            solo
            prepend-inner-icon="filter_list"
            :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : ''"
            @change="reloadEvents"
          >
            <template v-slot:selection="{ item, index }">
              <template v-if="eventTypeFilter.length !== eventTypes.length && index === 0">
                <span class="grey--text mr-1">{{ $t('dashboard.filtered') }}</span>
                <span class="grey--text caption">({{ eventTypeFilter.length }} {{ $t('dashboard.selections') }})</span>
              </template>
              <template v-else>
                <span v-if="index === 0" class="grey--text">{{ $t('dashboard.show_all') }}</span>
              </template>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-col cols="12" v-if="!loading && filteredEvents.length === 0">
        <NoContentView
          :title="$t('dashboard.no_events')"
          :description="$t('dashboard.no_events_help')"
          icon="mdi mdi-view-dashboard"
          :hide-button="true"
        />
      </v-col>
      <v-list v-else class="py-0" :dense="$vuetify.breakpoint.xsOnly">
        <template v-for="(event, index) in filteredEvents" >
          <v-list-item :key="event.id" @click="goToSource(event)" :class="{ mention: hasMention(event), my: isMyMention(event) }">

              <v-list-item-avatar :color="event.eventType === 'CREATED' ? 'secondary' : 'grey lighten-4'">
                <v-icon :color="event.eventType === 'CREATED' ? 'white' : getReferenceTypeColors(event.referenceType)">
                  {{ getReferenceTypeIcon(event.referenceType) }}
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="pr-5">
                <v-list-item-title>
                  {{ event.referenceType === 'APPLICANT' || event.referenceType === 'APPLICATION' ? $t(`dashboard.event_types.${event.referenceType}_${event.eventType}`) : $t(`dashboard.event_types.${event.eventType}`) }}
                </v-list-item-title>

                <template v-if="$vuetify.breakpoint.xsOnly">
                  <v-list-item-subtitle v-if="event.createdBy" class="caption mb-0">
                    {{ event.createdBy.firstName && event.createdBy.lastName ? `${event.createdBy.firstName} ${event.createdBy.lastName}` : event.createdBy.email }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="caption mb-2">
                    {{ $formatTimeStamp(event.createdAt) }}
                  </v-list-item-subtitle>
                </template>

                <v-list-item-subtitle v-if="event.comment" :class="{ comment: event.eventType === 'COMMENT' }">
                  {{ event.comment }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="event.applicant">
                  {{ event.applicant.firstName && event.applicant.lastName ? `${$t('dashboard.applicant')}: ${event.applicant.firstName} ${event.applicant.lastName}` : event.applicant.email }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="!textEvents.includes(event.eventType)">
                  <template v-if="event.oldValue">
                    <hb-status-chip v-if="event.oldValue !== 'NONE'"
                                    :value="event.oldValue"
                                    x-small
                                    class="white--text"
                                    :type="event.eventType === 'RESULT_CHANGE' ? 'result' : event.eventType === 'STATUS_CHANGE' ? 'status' : null"></hb-status-chip>
                    <v-icon size="16">mdi mdi-arrow-right</v-icon>
                  </template>
                  <template v-if="event.newValue">
                    <hb-status-chip v-if="event.newValue !== 'NONE'"
                                    :value="event.newValue"
                                    x-small
                                    class="white--text"
                                    :type="event.eventType === 'RESULT_CHANGE' ? 'result' : event.eventType === 'STATUS_CHANGE' ? 'status' : null"></hb-status-chip>
                  </template>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text v-if="$vuetify.breakpoint.smAndUp" class="text-right">
                <template v-if="event.createdBy">
                  {{ event.createdBy.firstName && event.createdBy.lastName ? `${event.createdBy.firstName} ${event.createdBy.lastName}` : event.createdBy.email }}
                  <br />
                </template>
                {{ $formatTimeNoDate(event.createdAt) }}
                <br />
                {{ $formatTimeStampShort(event.createdAt) }}
              </v-list-item-action-text>
          </v-list-item>

          <v-divider v-if="index < filteredEvents.length - 1" :key="`${event.id}_divider`" />
        </template>

      </v-list>
    </v-col>

  </hb-basic-page>
</template>
<script>
import HbStatusChip from "@/components/Tenant/HbStatusChip.vue";
import NoContentView from "@/components/NoContentView";
import moment from "moment";

export default {
  name: "DashboardView",
  components: {NoContentView, HbStatusChip},
  data() {
    return {
      loading: true,
      loadingEvents: true,
      mentions: true,
      tenantUsers: [],
      dateRange: [
        moment().subtract(14,'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ],
      dateRangeFormatted: '',
      dateRangeMenu: false,
      eventTypes: [
        { value: 'STATUS_CHANGE', desc: this.$t('dashboard.event_types.STATUS_CHANGE'), },
        { value: 'RESULT_CHANGE', desc: this.$t('dashboard.event_types.RESULT_CHANGE') },
        { value: 'COMMENT', desc: this.$t('dashboard.event_types.APPLICATION_COMMENT') },
        { value: 'INTERNAL_NOTE', desc: this.$t('dashboard.event_types.APPLICATION_INTERNAL_NOTE') },
        { value: 'INTERVIEW_NOTE', desc: this.$t('dashboard.event_types.APPLICATION_INTERVIEW_NOTE') },
        { value: 'CREATED', desc: this.$t('dashboard.event_types.APPLICATION_CREATED') },
      ],
      eventTypeFilter: [
        'STATUS_CHANGE',
        'RESULT_CHANGE',
        'COMMENT',
        'INTERNAL_NOTE',
        'INTERVIEW_NOTE',
        'CREATED',
      ],
      allEvents: [],
      totalEvents: 0,
      referenceTypeColors: {
        'APPLICATION': 'primary',
        'APPLICANT': 'accent darken-2',
        'JOB_AD': 'secondary',
      },
      referenceTypeIcons: {
        'APPLICATION': 'mdi mdi-card-bulleted-outline',
        'APPLICANT': 'mdi mdi-card-account-details-outline',
        'JOB_AD': 'mdi mdi-magnify-scan',
      },
      comment: '',
    }
  },
  watch: {
    dateRange(val) {
      if (val && val.length === 2) {
        this.dateRangeFormatted = `${this.$formatDateNoTime(val[0])} - ${this.$formatDateNoTime(val[1])}`;
      } else {
        this.dateRangeFormatted = '';
      }
    },
    dateRangeMenu(val) {
      if (!val) {
        if (this.dateRange.length === 1) {
          this.dateRange = [
            this.dateRange[0],
            moment().format('YYYY-MM-DD'),
          ];
          this.reloadEvents();
        }
      }
    },
  },
  computed: {
    mentionableItems() {
      return this.tenantUsers.filter(u => u.firstName && u.lastName).map(u => ({
        value: `${u.firstName}.${u.lastName}`.toLowerCase(),
        label: `${u.firstName} ${u.lastName}`,
      }));
    },
    textEvents() {
      return ['COMMENT', 'INTERNAL_NOTE', 'INTERVIEW_NOTE'];
    },
    filteredEvents() {
      let events = this.allEvents;
      if (this.mentions) {
        events = events.filter(e => this.hasMention(e) || this.isMyMention(e));
      }
      return events;
    },
  },
  methods: {
    dateRangeOnClose() {
    },
    async dateRangeChange() {
      this.dateRangeMenu = false;
      await this.reloadEvents();
    },
    async reloadTenantUsers() {
      this.loading = true;
      this.tenantUsers = await this.$tenantApi.loadTenantUsers();
      this.loading = false;
    },
    async createDashboardComment() {
      try {
        const commentEvent = await this.$tenantApi.createDashboardComment(this.comment);
        this.allEvents.unshift(commentEvent);
        this.comment = '';
      } catch (e) {
        this.$handleApiError(e);
      }
    },
    isMyMention(event) {
      const u = this.$userInfo;
      if (!event.createdBy || event.createdBy.id !== u.id) {
        return false;
      }
      const regex = /@(\w+\.\w+)/;
      regex.test(event.comment);
      return regex.test(event.comment);
    },
    hasMention(event) {
      const u = this.$userInfo;
      if (u.firstName && u.lastName) {
        const tag = `@${u.firstName}.${u.lastName}`.toLowerCase();
        return event.comment && event.comment.includes(tag);
      } else {
        return false;
      }
    },
    async reloadEvents() {
      this.loadingEvents = true;
      try {
        const response = await this.$tenantApi.searchEvents({
          pageNo: 1,
          pageSize: -1,
          sortBy: 'createdAt',
          descending: true,
          eventType: this.eventTypeFilter,
          createdAtRange: [
            moment(this.dateRange[0]).startOf('day'),
            moment(this.dateRange[1]).endOf('day')
          ],
        });

        this.allEvents = response.content;
        this.totalEvents = response.totalElements;
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loadingEvents = false;
    },
    goToSource(event) {
      if (event.referenceType === 'APPLICATION') {
        this.$router.push({name: 'single_application', params: {id: event.jobAdId, applicationId: event.applicationId}});
      } else if (event.referenceType === 'APPLICANT') {
        this.$router.push({name: 'single_applicant', params: {id: event.applicantId}});
      } else if (event.referenceType === 'JOB_AD') {
        this.$router.push({name: 'job_ad', params: {id: event.jobAdId}});
      }
    },
    getReferenceTypeColors(type) {
      return this.referenceTypeColors[type];
    },
    getReferenceTypeIcon(type) {
      return this.referenceTypeIcons[type];
    }
  },
  async mounted() {
    await this.reloadTenantUsers();
    await this.reloadEvents();
    this.dateRangeFormatted = `${this.$formatDateNoTime(this.dateRange[0])} - ${this.$formatDateNoTime(this.dateRange[1])}`;
    this.loading = false;
  }
}
</script>
<style scoped>
.my {
  border: 3px solid lightblue;
  outline: none;
}
.mention {
  border: 2px solid lightgreen;
  outline: none;
  box-shadow: 0 0 10px limegreen;
}
.comment {
  word-wrap: break-word;
  white-space: pre-wrap
}
</style>
