<template>
  <v-dialog v-model="dialog"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            :max-width="$vuetify.breakpoint.xsOnly ? '' : '800'"
            :hide-overlay="$vuetify.breakpoint.xsOnly"
            :transition="$vuetify.breakpoint.xsOnly ? 'dialog-bottom-transition' : ''">
    <v-card :tile="$vuetify.breakpoint.xsOnly" v-if="file">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ file.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0 scroll-area" ref="scrollArea">
        <div class="canvas-container">
          <canvas ref="pdfCanvas"></canvas>
        </div>
      </v-card-text>
      <v-divider v-if="$vuetify.breakpoint.smAndUp"></v-divider>
      <v-card-actions>
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-btn icon @click="prevPage" :disabled="pageNum <= 1"><v-icon>mdi mdi-chevron-left</v-icon></v-btn>
          <span>{{ $t('application.cv_page') }} {{ pageNum }}/{{ totalPages }}</span>
          <v-btn icon @click="nextPage" :disabled="pageNum >= totalPages"><v-icon>mdi mdi-chevron-right</v-icon></v-btn>
        </template>
        <v-spacer></v-spacer>
        <v-btn color="primary"
               @click="close"
               :loading="loading"
               :fab="$vuetify.breakpoint.xsOnly"
               :fixed="$vuetify.breakpoint.xsOnly"
               :bottom="$vuetify.breakpoint.xsOnly"
               :right="$vuetify.breakpoint.xsOnly">
          <v-icon v-if="$vuetify.breakpoint.xsOnly">close</v-icon>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('close') }}</span>
        </v-btn>
      </v-card-actions>


      <div v-if="$vuetify.breakpoint.xsOnly" style="position: fixed; left: 10px; bottom: 10px;">
        <v-btn icon @click="prevPage" :disabled="pageNum <= 1"><v-icon>mdi mdi-chevron-left</v-icon></v-btn>
        <span>{{ $t('application.cv_page') }} {{ pageNum }}/{{ totalPages }}</span>
        <v-btn icon @click="nextPage" :disabled="pageNum >= totalPages"><v-icon>mdi mdi-chevron-right</v-icon></v-btn>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import * as pdfjs from 'pdfjs-dist';
import pdfWorker from "pdfjs-dist/build/pdf.worker.entry";

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

export default {
  name: "PDFViewer",
  data() {
    return {
      loading: true,
      dialog: false,
      pdfUrl: null,
      link: null,
      file: null,
      pageNum: 1,
      totalPages: 1,
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.pdfUrl = null;
        URL.revokeObjectURL(this.link.href);
        this.link = null;
        this.file = null;
        this.pageNum = 1;
        this.totalPages = 1;
      }
    }
  },
  methods: {
    open(link, file) {
      this.pdfUrl = link.href;
      this.file = file;
      this.link = link
      this.dialog = true;
      this.loadPdf();
    },
    async loadPdf() {
      if (!this.pdfUrl) return;
      this.loading = true;

      this.pdfDoc = await pdfjs.getDocument(this.pdfUrl).promise;
      this.totalPages = this.pdfDoc.numPages;
      this.pageNum = 1;

      this.renderPage(this.pageNum);
    },
    async renderPage(num) {
      const page = await this.pdfDoc.getPage(num);
      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = this.$refs.pdfCanvas;
      const context = canvas.getContext("2d");

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      await page.render({ canvasContext: context, viewport }).promise;
      this.loading = false;
    },
    nextPage() {
      if (this.pageNum < this.totalPages) {
        this.pageNum++;
        this.renderPage(this.pageNum);
        this.$refs.scrollArea.scrollTop = 0;
      }
    },
    prevPage() {
      if (this.pageNum > 1) {
        this.pageNum--;
        this.renderPage(this.pageNum);
        this.$refs.scrollArea.scrollTop = 0;
      }
    },
    close() {
      this.dialog = false;
    }
  },
}
</script>
<style scoped>

.scroll-area {
  max-height: calc(100vh - 56px);
  overflow-y: auto;
}

canvas {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .scroll-area {
    max-height: 70vh;
    overflow-y: auto;
  }
}

.canvas-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>

