import axios from 'axios';
import Helper from "@/helper";
/*
 * Used for tenant API calls.
 * for example: get tenants users, get tenants data
 *
 * X-TENANT-ID header required for ALL calls
 */

const tenantApi = {
  setSelectedTenant(tenant) {
    axios.defaults.headers.common['X-tenant-id'] = tenant;
  },
  async getCurrentTenant() {
    const response = await axios.get('/api/tenant');
    return response.data;
  },
  /**
   *
   * @param {!object} data
   * @param {!string} data.name
   * @param {!string} data.postApplicationGuideText
   * @param {!string} data.postApplicationButtonText
   * @param {!string} data.postApplicationButtonRedirectUrl
   * @param {!boolean} data.postApplicationButtonVisible
   * @returns {Promise<any>}
   */
  async updateCurrentTenant(data) {
    const requestData = {
      name: data.name,
      postApplicationGuideText: data.postApplicationGuideText,
      postApplicationButtonText: data.postApplicationButtonText,
      postApplicationButtonRedirectUrl: data.postApplicationButtonRedirectUrl,
      postApplicationButtonVisible: data.postApplicationButtonVisible,
    }
    const response = await axios.post('/api/tenant', requestData);
    return response.data;
  },
  async getHellos() {
    const response = await axios.get('/api/tenant/hello')
    return response.data;
  },
  async addHello(msg) {
    const response = await axios.post('/api/tenant/hello', { msg });
    return response.data;
  },
  async deleteHello(helloId) {
    const response = await axios.delete(`/api/tenant/hello/${helloId}`);
    return response.data;
  },
  async loadTenantUsers() {
    const response = await axios.get('/api/tenant/user');
    return response.data;
  },
  /**
   *
   * @param {!Object} data
   * @param {!string} data.firstName
   * @param {!string} data.lastName
   * @param {!string} data.email
   * @returns {Promise<any>}
   */
  async addNewUserToTenant(data) {
    const response = await axios.post('/api/tenant/user', {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    });
    return response.data;
  },
  async removeUserFromTenant(userId) {
    const response = await axios.delete(`/api/tenant/user/${userId}`);
    return response.data;
  },
  async getRoles() {
    const response = await axios.get('/api/tenant/roles');
    return response.data;
  },
  async addRole(subject, role) {
    const response = await axios.post(`/api/tenant/user/${subject}/role/${role}`);
    return response.data;
  },
  async deleteRole(subject, role) {
    const response = await axios.delete(`/api/tenant/user/${subject}/role/${role}`);
    return response.data;
  },
  /**
   *
   * @param {Object} tagRequest
   * @param {String} tagRequest.description
   * @returns {Promise<void>}
   */
  async addTag(tagRequest) {
    const data = {
      description: tagRequest.description,
    };

    const response = await axios.post('/api/tenant/tag', data);
    return response.data;
  },
  async addQuestion(createQuestionRequest) {
    const data = {
      type: createQuestionRequest.type,
      questionText: createQuestionRequest.questionText,
      quiz: createQuestionRequest.quiz,
      answers: createQuestionRequest.answers,
      requiredTags: createQuestionRequest.requiredTags,
      exclusiveTags: createQuestionRequest.exclusiveTags,
      classificationTags: createQuestionRequest.classificationTags,
    };

    const response = await axios.post('/api/tenant/question', data);
    return response.data;
  },
  async updateQuestion(questionId, updateQuestionRequest) {
    const data = {
      questionText: updateQuestionRequest.questionText,
      quiz: updateQuestionRequest.quiz,
      answers: updateQuestionRequest.answers,
      requiredTags: updateQuestionRequest.requiredTags,
      exclusiveTags: updateQuestionRequest.exclusiveTags,
      classificationTags: updateQuestionRequest.classificationTags,
    };

    const response = await axios.post('/api/tenant/question/' + questionId, data);
    return response.data;
  },
  async deleteQuestion(id) {
    const response = await axios.delete('/api/tenant/question/' + id);
    return response.data;
  },

  async addQuestionToJob(questionId, id) {
    const response = await axios.post('/api/tenant/job_ad/' + id + '/question/' + questionId);
    return response.data;
  },
  async removeQuestionFromJob(questionId, id) {
    const response = await axios.delete('/api/tenant/job_ad/' + id + '/question/' + questionId);
    return response.data;
  },
  /**
   *
   * @param jobAdId
   * @param tagId
   * @returns {Promise<any>}
   */
  async addTagToJobAd(jobAdId, tagId) {
    const response = await axios.post(`/api/tenant/job_ad/${jobAdId}/tag/${tagId}`);
    return response.data;
  },
  async getQuestions() {
    const response = await axios.get('/api/tenant/question');
    return response.data;
  },
  async getQuestion(questionId) {
    const response = await axios.get('/api/tenant/question/' + questionId);
    return response.data;
  },
  async getTags() {
    const response = await axios.get('/api/tenant/tag');
    return response.data.map(t => t.description);
  },
  async getJobAds() {
    const response = await axios.get('/api/tenant/job_ad');
    return response.data;
  },
  async getJobAdsPaged(filter = {}, options = {}) {
    const response = await axios({
      url: '/api/tenant/job_ad/paged',
      method: 'post',
      params: { ...Helper.tableOptionsToRestPageParams(options) },
      data: filter,
    });
    return response.data;
  },
  async getJobAd(jobAdId) {
    const response = await axios.get(`/api/tenant/job_ad/${jobAdId}`);
    return response.data;
  },
  async createJobAd(data) {
    const response = await axios.post('/api/tenant/job_ad', data);
    return response.data;
  },
  async updateJobAd(id, data) {
    const response = await axios.post(`/api/tenant/job_ad/${id}`, data);
    return response.data;
  },
  async updateJobAdImage(id, file, imageFilename) {
    const formData = new FormData();
    formData.append('file', file, imageFilename);
    const response = await axios.post(`/api/tenant/job_ad/${id}/image`, formData);
    return response.data;
  },
  async deleteJobAdImage(id) {
    const response = await axios.delete(`/api/tenant/job_ad/${id}/image`);
    return response.data;
  },
  async updateJobAdCustomerImage(id, file, imageFilename) {
    const formData = new FormData();
    formData.append('file', file, imageFilename);
    const response = await axios.post(`/api/tenant/job_ad/${id}/customer_image`, formData);
    return response.data;
  },
  async deleteJobAdCustomerImage(id) {
    const response = await axios.delete(`/api/tenant/job_ad/${id}/customer_image`);
    return response.data;
  },
  async reorderJobAdQuestions(jobAdId, questionIds) {
    const response = await axios.post(`/api/tenant/job_ad/${jobAdId}/question_order`, questionIds);
    return response.data;
  },
  async getOnlineTestQuestions() {
    const response = await axios.get(`/api/tenant/online_test/question`);
    return response.data;
  },
  async removeQuestionFromOnlineTest(questionId) {
    const response = await axios.delete(`/api/tenant/online_test/question/${questionId}`);
    return response.data;
  },
  async reorderOnlineTestQuestions(questionIds) {
    const response = await axios.post(`/api/tenant/online_test/question_order`, questionIds);
    return response.data;
  },
  async setJobAdRequiredTags(jobAdId, tags) {
    const response = await axios.post(`/api/tenant/job_ad/${jobAdId}/required_tag`, tags);
    return response.data;
  },
  async setJobAdExclusiveTags(jobAdId, tags) {
    const response = await axios.post(`/api/tenant/job_ad/${jobAdId}/exclusive_tag`, tags);
    return response.data;
  },
  async updateJobAdsFromLikeit() {
    const response = await axios.get('/api/tenant/job_ad', { params: { update: 1 }});
    return response.data;
  },
  async setOpenApplication(jobAdId, openApplication) {
    const response = await axios.post(`/api/tenant/job_ad/${jobAdId}/open_application/${openApplication}`);
    return response.data;
  },
  async updateApplication(applicationId, data) {
    const response = await axios.post(`/api/tenant/application/${applicationId}`, data);
    return response.data;
  },
  async getApplications(jobId) {
    const response = await axios.get(`/api/tenant/application/job/${jobId}`);
    return response.data;
  },
  async getApplication(applicationId) {
    const response = await axios.get(`/api/tenant/application/${applicationId}`);
    return response.data;
  },
  async getReportData(applicationId) {
    const response = await axios.get(`/api/tenant/application/${applicationId}/reportdata`);
    return response.data;
  },
  async generateReport(applicationId) {
    const response = await axios.get(`/api/tenant/application/${applicationId}/report`, {
      responseType: 'blob',
      params: {
      },
    });
    return response.data;
  },
  async createApplication(data) {
    const response = await axios.post(`/api/tenant/application`, data);
    return response.data;
  },
  async getApplicationComments(applicationId) {
    const response = await axios.get(`/api/tenant/application/comment/${applicationId}`);
    return response.data;
  },
  async createApplicationComment(applicationId, message) {
    const data = { message };
    const response = await axios.post(`/api/tenant/application/comment/${applicationId}`, data);
    return response.data;
  },
  async deleteApplicationComment(commentId) {
    const response = await axios.delete(`/api/tenant/application/comment/${commentId}`);
    return response.data;
  },
  async editApplicationComment(applicationId, commentId, data) {
    const response = await axios.post(`/api/tenant/application/comment/${applicationId}/${commentId}`, data);
    return response.data;
  },

  // eslint-disable-next-line no-unused-vars
  async getApplicantComments(applicantId) {
    return new Error('Not implemented');
  },
  async getApplicantById(applicantId) {
    if (!applicantId) throw new Error('applicantId is required');
    const response = await axios.get(`/api/tenant/applicant/${applicantId}`);
    return response.data;
  },
  async updateApplicant(id, data) {
    const response = await axios.post(`/api/tenant/applicant/${id}`, data);
    return response.data;
  },
  async deleteApplicant(id) {
    const response = await axios.delete(`/api/tenant/applicant/${id}`);
    return response.data;
  },
  async getIndustries() {
    const response = await axios.get('/api/tenant/industry');
    return response.data;
  },
  async getLicenses() {
    const response = await axios.get('/api/tenant/license');
    return response.data;
  },
  async searchApplicants(options) {
    const response = await axios.post('/api/tenant/applicant/search', options);
    return response.data;
  },
  async searchApplications(options) {
    const response = await axios.post('/api/tenant/application/search', options);
    return response.data;
  },
  async getSuggestedApplicants(options) {
    const response = await axios.post('/api/tenant/applicant/suggested', options);
    return response.data;
  },
  async createFileAccessToken(file) {
    const response = await axios.get(`/api/tenant/file/${file.applicantId}/access`);
    return response.data;
  },
  async downloadFile(fileId) {
    // TODO: token
    const response = await axios.get(`/api/tenant/file/${fileId}?token=123`, {responseType: 'blob'});
    return response.data;
  },
  async getFilesByApplicant(applicantId) {
    const response = await axios.get(`/api/tenant/applicant/${applicantId}/file`);
    return response.data;
  },
  async getFilesByApplication(applicationId) {
    const response = await axios.get(`/api/tenant/application/${applicationId}/file`);
    return response.data;
  },
  async updateJobAdLicenses(jobAdId, licenseCodes) {
    const response = await axios.post(`/api/tenant/job_ad/${jobAdId}/license`, licenseCodes);
    return response.data;
  },
  async getEvents() {
    const response = await axios.get('/api/tenant/event');
    return response.data;
  },
  async searchEvents(options) {
    const response = await axios.post('/api/tenant/event/search', options);
    return response.data;
  },
  async getCustomers() {
    const response = await axios.get('/api/tenant/customer');
    return response.data;
  },
  async createCustomer(data) {
    const response = await axios.post('/api/tenant/customer', data);
    return response.data;
  },
  async updateCustomer(id, data) {
    const response = await axios.post(`/api/tenant/customer/${id}`, data);
    return response.data;
  },
  async deleteCustomer(id) {
    const response = await axios.delete(`/api/tenant/customer/${id}`);
    return response.data;
  },
  async sendEmail(data) {
    const response = await axios.post('/api/tenant/email/', data);
    return response.data;
  },
  async createDashboardComment(message) {
    const data = { message };
    const response = await axios.post(`/api/tenant/event/comment/`, data);
    return response.data;
  },
  async searchCommunications(options) {
    const response = await axios.post('/api/tenant/communication/search', options);
    return response.data;
  },
  async uploadCv(applicantId, applicationId, file, uploadProgressCallBack) {
    const formData = new FormData();
    formData.append('file', file.file, file.filename);
    formData.append('name', file.name);
    formData.append('type', file.type);
    const response = await axios.post(`/api/shared/online_test/${applicantId}/cv/${applicationId}`, formData, { onUploadProgress: uploadProgressCallBack });
    return response.data;
  },
  async uploadAdditionalFile(applicantId, applicationId, file, uploadProgressCallBack) {
    const formData = new FormData();
    formData.append('file', file.file, file.filename);
    formData.append('name', file.name);
    formData.append('type', file.type);
    const response = await axios.post(`/api/shared/online_test/${applicantId}/file/${applicationId}`, formData, { onUploadProgress: uploadProgressCallBack });
    return response.data;
  },
};

export default tenantApi;
