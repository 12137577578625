import Vue from 'vue';

function getTenantFromQuery() {
  const url = window.location.href;

  const params = new URLSearchParams(new URL(url).search);

  const t = params.get('t');
  return t;
}

export default new Vue({
  data() {
    return {
    }
  },
  methods: {
    getTenant() {
      return getTenantFromQuery() || window.online_test_tenant_id || localStorage.getItem('tenant');
    },
    getAnswers() {
      const tenant = this.getTenant();
      const onlineTest = JSON.parse(localStorage.getItem(`online_test_${tenant}`)) || {};
      return onlineTest.answers || {};
    },
    getTextAnswers() {
      const tenant = this.getTenant();
      const onlineTest = JSON.parse(localStorage.getItem(`online_test_${tenant}`)) || {};
      return onlineTest.textAnswers || {};
    },
    addAnswers(answers, textAnswers) {
      if (answers) {
        const tenant = this.getTenant();
        const onlineTest = JSON.parse(localStorage.getItem(`online_test_${tenant}`)) || {};
        const previousAnswers = onlineTest.answers || {};
        const previousTextAnswers = onlineTest.textAnswers || {};
        const newAnswers = {...previousAnswers, ...answers};
        const newTextAnswers = {...previousTextAnswers, ...textAnswers};
        localStorage.setItem(`online_test_${tenant}`, JSON.stringify({
          ...onlineTest,
          answers: newAnswers,
          textAnswers: newTextAnswers
        }));
        return newAnswers;
      } else {
        throw 'invalid answers';
      }
    },
  },
});
