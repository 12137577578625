<template>
  <v-select
    :value="value"
    :items="allStates"
    :label="$t('job_ads.states_title')"
    class="mb-3"
    multiple
    hide-details
    single-line
    solo
    prepend-inner-icon="filter_list"
    deletable-chips
    chips
    small-chips
    @change="updateSelectedStates"
  >
    <template v-slot:selection="{ item, index }">
      <template v-if="$vuetify.breakpoint.smAndDown">
        <template v-if="allStates.length !== value.length && index === 0">
          <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
          <span class="grey--text caption">({{ value.length }} {{ $t('user_management.selections') }})</span>
        </template>
        <template v-else>
          <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
        </template>
      </template>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <template v-if="allStates.length !== value.length && index < 2">
          <v-chip small>{{ item.text }}</v-chip>
        </template>
        <template v-if="allStates.length !== value.length && index > 1 && index < 3">
          <span class="grey--text caption">+ {{ value.length - 2 }} {{ $t('job_ads.other_selections') }}</span>
        </template>
        <template v-if="allStates.length === value.length">
          <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
        </template>
      </template>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'jobs-filter',
  props: {
    value: Array, // This binds to `v-model`
    allStates: Array,
    debouncedGetJobAds: Function
  },
  methods: {
    updateSelectedStates(newValue) {
      this.$emit('input', newValue); // Emit `input` event for `v-model`
      this.debouncedGetJobAds();
    }
  }
}
</script>
