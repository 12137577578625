<template>
  <hb-basic-page :title="$t('communication.title')"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 :fill-height="loading"
                 :no-gutters="true"
                 name="communication-view">
    <v-row class="flex-wrap" justify="center">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :page="pageNo"
          :items-per-page="pageSize"
          :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :must-sort="true"
          @update:sort-by="reloadItems"
          @update:sort-desc="reloadItems"
          @pagination="paginate"
          :loading="subtleLoading"
          :no-data-text="$t('communication.no_data')"
          :no-results-text="$t('communication.no_data')"
          @click:row="expand"
        >
          <template v-slot:item.subject="{item}">
            <div class="py-2">
              <div class="subtitle-1 font-weight-bold">{{ item.subject }}</div>
              <div v-if="item.expanded" style="white-space: pre-line;" class="py-2 mx-n2 px-2 grey lighten-5 rounded caption">{{ item.message }}</div>
            </div>
          </template>
          <template v-slot:item.recipientCount="{ item }">
            <span class="caption" v-if="!item.expanded">{{ item.recipients.length }}</span>
            <template v-if="item.expanded">
              <div
                v-for="r in item.recipients"
                :key="r.value"
                class="caption"
                style="line-height: 14px;"
              >
                {{ recipientText(r) }}
              </div>
            </template>
          </template>
          <template v-slot:item.status="{ item }">
            <span class="caption">{{ $t(`communication.statuses.${item.status}`) }}</span>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span class="caption">{{ $formatTimeStamp(item.createdAt) }}</span>
          </template>
          <!-- TODO: muita toimintoja?
          <template v-slot:item.actions="{ item }">
            <v-btn color="primary" icon small @click="expand(item)">
              <v-icon v-if="item.expanded">mdi mdi-magnify-minus</v-icon>
              <v-icon v-else>mdi mdi-magnify-plus</v-icon>
            </v-btn>
          </template>
          -->
        </v-data-table>
      </v-col>
    </v-row>
  </hb-basic-page>
</template>

<script>
export default {
  name: 'CommunicationView',
  data() {
    return {
      loading: false,
      subtleLoading: false,
      items: [],
      headers: [
        { text: this.$t('communication.subject'), value: 'subject', sortable: false },
        { text: this.$t('communication.sender'), value: 'sender', sortable: false },
        { text: this.$t('communication.recipient_count'), value: 'recipientCount', sortable: false },
        { text: this.$t('communication.status'), value: 'status', sortable: false },
        { text: this.$t('communication.created_at'), value: 'createdAt' },
      ],
      totalItems: 0,
      pageSize: 50,
      pageNo: 1,
      sortBy: 'createdAt',
      sortDesc: true,
    };
  },
  methods: {
    recipientText(r) {
      if (!r.status || r.status === 'SUCCESS') {
        return r.value;
      } else {
        return  `${r.value} (${this.$t(`communication.statuses.${r.status}`)})`;
      }

    },
    expand(item) {
      this.$set(item, 'expanded', !item.expanded);
    },
    async reloadItems() {
      this.subtleLoading = true;
      try {
        const response = await this.$tenantApi.searchCommunications({
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          search: this.innerSearchPhrase,
          sortBy: this.sortBy,
          descending: this.sortDesc,
        });
        this.items = response.content;
        this.totalItems = response.totalElements;
      } catch (error) {
        this.$handleApiError(error);
      }
      this.subtleLoading = false;
    },
    async paginate(p) {
      this.pageNo = p.page;
      this.pageSize = p.itemsPerPage;
      await this.reloadItems();
    },
  },
  async mounted() {
    await this.reloadItems();
  }
}
</script>

<style scoped>

</style>
