<template>
  <hb-basic-page :title="$tc('job_ads.title', 2)"
                 :search-title="`${$t('job_ads.search')}...`"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 ref="basicPage"
                 @handle-search="handleSearch"
                 :fill-height="loading || (job_ads && job_ads.length === 0)"
                 no-gutters
                 name="job-ads-view">

    <v-col cols="12" md="7" lg="8" xl="8" :style="{height: $vuetify.breakpoint.xsOnly ? '0' : 'auto'}">
      <v-btn
        @click="addJobAd"
        :loading="loading || subtleLoading"
        color="primary"
        dark
        v-if="!loading && (job_ads && job_ads.length > 0)"
        :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
        v-show="$vuetify.breakpoint.mdAndUp || ready"
        :fab="$vuetify.breakpoint.smAndDown"
        :fixed="$vuetify.breakpoint.smAndDown"
        :bottom="$vuetify.breakpoint.smAndDown"
        :right="$vuetify.breakpoint.smAndDown"
        :class="{'ma-0': $vuetify.breakpoint.mdAndUp}">
        <v-icon :left="$vuetify.breakpoint.mdAndUp">add</v-icon>
        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('job_ads.add_job_ad') }}</span>
      </v-btn>
    </v-col>

    <v-col cols="12" md="5" lg="4" xl="4">
      <template v-if="!loading && job_ads && job_ads.length > 0">
        <v-select
          v-model="selectedStates"
          :items="allStates"
          :label="$t('job_ads.states_title')"
          class="mb-3"
          multiple
          hide-details
          single-line
          solo
          prepend-inner-icon="filter_list"
          deletable-chips
          chips
          small-chips
          @change="debouncedGetJobAds"
        >
          <template v-slot:selection="{ item, index }">
            <template v-if="$vuetify.breakpoint.smAndDown">
              <template v-if="allStates.length !== selectedStates.length && index === 0">
                <span class="grey--text mr-1">{{ $t('user_management.filtered') }}</span>
                <span class="grey--text caption">({{ selectedStates.length }} {{ $t('user_management.selections') }})</span>
              </template>
              <template v-else>
                <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
              </template>
            </template>
            <template v-if="$vuetify.breakpoint.mdAndUp" >
              <template v-if="allStates.length !== selectedStates.length && index < 2">
                <v-chip small>{{ item.text }}</v-chip>
              </template>
              <template v-if="allStates.length !== selectedStates.length && index > 1">
                <span class="grey--text caption">+ {{ selectedStates.length - 2 }} {{ $t('job_ads.other_selections') }}</span>
              </template>
              <template v-if="allStates.length === selectedStates.length">
                <span v-if="index === 0" class="grey--text">{{ $t('user_management.show_all') }}</span>
              </template>
            </template>
          </template>
        </v-select>
      </template>
    </v-col>

    <v-col cols="12">
      <template v-if="!loading && job_ads && job_ads.length > 0">
        <v-pagination v-model="options.page" :length="length" total-visible="11" @input="getJobAds" />
        <v-card class="rounded my-4" v-if="job_ads && job_ads.length > 0">
          <v-card-text class="pa-0">
            <v-virtual-scroll v-if="job_ads && job_ads.length > 0" item-height="117" :height="`calc(100vh - ${$vuetify.breakpoint.smAndDown ? '252' : '265'}px)`" :items="job_ads" bench="5">
              <template v-slot:default="{item}">
                <JobListItem :job="item" :key="item.id" />
                <v-divider :key="`${item.id}_divider`"></v-divider>
              </template>
            </v-virtual-scroll>
          </v-card-text>
        </v-card>
        <v-pagination v-model="options.page" :length="length" total-visible="11" @input="getJobAds" />
      </template>

      <template v-if="!loading && job_ads && job_ads.length === 0">
        <NoContentView :title="$t('job_ads.no_job_ads')" :description="$likeitEnabled ? $t('job_ads.no_job_ads_help') : $t('job_ads.no_job_ads_help_no_likeit')" icon="mdi mdi-magnify-scan" />

        <div style="margin-top: -50px; padding-bottom: 50px;">
          <div class="text-center">
            <v-fab-transition>
              <v-btn
                @click="clearFilters"
                :loading="loading || subtleLoading"
                color="secondary"
                dark
                v-if="!loading && isFiltered"
                v-show="ready"
                class="mb-4">
                <v-icon :left="$vuetify.breakpoint.mdAndUp">mdi-filter-variant-remove</v-icon>
                <span>{{ $t('job_ads.clear_filters') }}</span>
              </v-btn>
            </v-fab-transition>
          </div>
          <div class="text-center">
            <v-fab-transition>
              <v-btn
                @click="addJobAd"
                :loading="loading || subtleLoading"
                color="primary"
                dark
                v-if="!loading && (job_ads && job_ads.length === 0)"
                :style="$store.state.bottomNavigation ? $bottomNavigationStyle : ''"
                v-show="$vuetify.breakpoint.mdAndUp || ready"
                :fab="$vuetify.breakpoint.smAndDown"
                :fixed="$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                :right="$vuetify.breakpoint.smAndDown"
                :class="{'ma-0': $vuetify.breakpoint.mdAndUp}">
                <v-icon :left="$vuetify.breakpoint.mdAndUp">add</v-icon>
                <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('job_ads.add_job_ad') }}</span>
              </v-btn>
            </v-fab-transition>
          </div>
        </div>
      </template>

    </v-col>

  </hb-basic-page>
</template>

<script>
import JobListItem from "@/components/Tenant/JobAds/JobListItem";
import NoContentView from "@/components/NoContentView";
import tenantApi from "@/api/tenant";
import {debounce} from "lodash";

export default {
  name: 'JobAdsView',
  components: {NoContentView, JobListItem},
  data() {
    return {
      loading: true,
      subtleLoading: false,
      options: {
        page: 0,
        itemsPerPage: 15,
      },
      length: 0,
      searchPhrase: '',
      selectedStates: [1, 3],
      allStates: [0, 1, 3, 2, 4, null].map(s => ({ value: s, text: this.$t('job_ads.states')[s]})),
      ready: false,
      debouncedGetJobAds: () => {},
    }
  },
  watch: {
    searchPhrase() {
      this.debouncedGetJobAds();
    },
  },
  methods: {
    addJobAd() {
      this.$router.push({name: 'job_ad_create'});
    },
    clearFilters() {
      this.selectedStates = [1,3];
      this.$refs.basicPage.clearSearch();
      this.$nextTick(() => {
        this.getJobAds()
      });
    },
    async reloadData(updateFromLikeit) {
      if (this.job_ads.length > 0) {
        this.subtleLoading = true;
        this.loading = false;
      } else {
        this.loading = true;
      }
      try {
        await this.$store.dispatch('reloadJobAds', {updateFromLikeit: updateFromLikeit, options: { page: 0, itemsPerPage: 25 }});
      } catch (error) {
        this.$handleApiError(error);
      }
      this.loading = false;
      this.subtleLoading = false;
    },
    async getJobAds() {
      this.subtleLoading = true;
      try {
        const filter = {
          states: this.selectedStates,
          searchString: this.searchPhrase,
        };
        const response = await tenantApi.getJobAdsPaged(filter, this.options);
        console.log(response);
        const jobAds = [...response.content];
        this.$store.commit('setJobAds', jobAds);
        this.options.page = response.pageable.pageNumber + 1;
        this.length = response.totalPages;
      } catch (error) {
        this.$handleApiError(error);
      }
      this.subtleLoading = false;
    },
    handleSearch(searchPhrase) {
      this.searchPhrase = searchPhrase;
    },
  },
  computed: {
    job_ads() {
      return this.$store.state.jobAds;
    },
    isFiltered() {
      return !!this.selectedStates.length || !!this.searchPhrase.length;
    },
  },
  mounted() {
    // this.reloadData();
    this.getJobAds();
    if (this.$vuetify.breakpoint.xsOnly) {
      setTimeout(() => {
        this.ready = true
      }, 300);
    } else {
      this.ready = true;
    }
    this.loading = false;
  },
  created() {
    this.debouncedGetJobAds = debounce(this.getJobAds, 500);
  },
}
</script>

<style scoped>

</style>
