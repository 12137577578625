<template>
  <v-card-text>
    <Mentionable :keys="['@']"
                 :items="mentionableItems">
      <v-textarea v-model="newMessage" outlined counter="1000" :label="$t('applicants.chat_new_comment')"></v-textarea>
      <template #item-@="{ item }">
        <v-list-item style="background-color: white; transform: translateX(100px); padding: 4px 10px; border-radius: 4px; font-family: urw-form,sans-serif;">
          {{ item.label }}
        </v-list-item>
      </template>
    </Mentionable>
    <v-btn class="primary mb-4" small block @click="addComment">
      <v-icon class="mr-2" left>mdi-send</v-icon>
      {{ $t('send') }}
    </v-btn>
    <v-divider></v-divider>
    <v-switch v-model="filterChanges" :label="$t('applicants.chat_filter_status_and_result')"></v-switch>
    <template v-if="!commentsLoading">
      <div class="chat-container px-1" ref="chatContainer">

        <div v-if="filteredComments && filteredComments.length">
          <div v-for="(comment, index) in filteredComments" :key="comment.id"
               :class="index % 2 === 0 ? 'grey lighten-2' : 'grey lighten-4'" class="chat-message my-1">
            <div class="chat-header pa-1 d-flex flex-row justify-space-between">
              <div>
                {{ comment.userFirstName }} {{ comment.userLastName }} ({{ comment.userEmail }})<br/>
                <span class="caption">{{ $formatTimeStamp(comment.created) }}</span>
              </div>

              <v-btn v-if="comment.type === 'COMMENT' && !comment.deleted && $userInfo.id === comment.userId"
                     icon small class="delete-icon mr-1" color="red" @click="deleteComment(comment)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn v-if="!comment.deleted && $userInfo.id === comment.userId"
                     icon small class="edit-icon mr-1" color="primary" @click="editComment(comment)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>

            <div class="chat-content mb-2 pa-1">
              <template v-if="comment.type ==='COMMENT'">
                <strong>{{ comment.message }}</strong>
              </template>
              <template v-else-if="comment.type ==='STATUS_CHANGE'">
                <span class="">{{ $t(`application.${comment.type}`) }}: </span>
                <hb-status-chip type="status" :value="comment.value"/>
                <strong class="ml-2">{{ comment.message }}</strong>
              </template>
              <template v-else-if="comment.type ==='RESULT_CHANGE'">
                <span class="">{{ $t(`application.${comment.type}`) }}: </span>
                <hb-status-chip type="result" :value="comment.value"/>
                <strong class="ml-2">{{ comment.message }}</strong>
              </template>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="mt-4 text-center">{{ $t('applicants.chat_no_messages') }}</div>
        </div>
      </div>
    </template>
    <v-skeleton-loader v-else type="paragraph@7" height="400px"></v-skeleton-loader>
    <edit-comment-dialog ref="editCommentDialog"/>
  </v-card-text>
</template>
<script>
import HbStatusChip from "@/components/Tenant/HbStatusChip.vue"
import tenantApi from "@/api/tenant";
import { Mentionable } from 'vue-mention';
import EditCommentDialog from '@/components/Tenant/SingleJobApplication/EditCommentDialog';

export default {
  name: 'internal-discussion',
  components: {HbStatusChip, EditCommentDialog, Mentionable},
  props: ['applicationId', 'applicantId', 'users'],
  data() {
    return {
      commentsLoading: false,
      comments: [],
      filterChanges: false,
      subtleLoading: false,
      newMessage: '',
    }
  },
  watch: {
    subtleLoading(to) {
      this.$emit('chat-loading', to);
    }
  },
  methods: {
    async fetchComments() {
      this.subtleLoading = true;
      if (!this.comments || !this.comments.length) {
        this.commentsLoading = true;
      }

      if (this.applicationId) {
        try {
          this.comments = await tenantApi.getApplicationComments(this.applicationId);
        } catch (error) {
          this.$handleApiError(error);
        }
        this.commentsLoading = false;
      }

      if (this.applicantId) {
        try {
          this.comments = await tenantApi.getApplicantComments(this.applicantId);
        } catch (error) {
          this.$handleApiError(error);
        }
        this.commentsLoading = false;
      }

      this.subtleLoading = false;
    },
    async addComment() {
      try {
        await tenantApi.createApplicationComment(this.applicationId, this.newMessage);
        this.newMessage = '';
        this.fetchComments();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async editComment(comment) {
      try {
        const [confirm, message] = await this.$refs.editCommentDialog.open({}, comment.message);
        if (confirm) {
          await tenantApi.editApplicationComment(this.applicationId, comment.id, { message });
          await this.fetchComments();
        }
      } catch (error) {
        this.$handleApiError(error);
      }
    },
    async deleteComment(comment) {
      try {
        await tenantApi.deleteApplicationComment(comment.id);
        await this.fetchComments();
      } catch (error) {
        this.$handleApiError(error);
      }
    },
  },
  computed: {
    filteredComments() {
      const newestFirst = [...this.comments].sort((a, b) => new Date(b.created) - new Date(a.created));
      return this.filterChanges ? newestFirst.filter(c => c.type === 'COMMENT') : newestFirst;
    },
    mentionableItems() {
      return this.users.filter(u => u.firstName && u.lastName).map(u => ({
        value: `${u.firstName}.${u.lastName}`.toLowerCase(),
        label: `${u.firstName} ${u.lastName}`,
      }));
    },
  }
}
</script>
<style scoped>
.chat-container {
    max-height: 500px; /* Adjust this value as needed */
    overflow-y: auto;
    scroll-behavior: smooth;
}
</style>
