<template>
  <hb-basic-page :title="$t('tenants.management_title')"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 :no-gutters="true"
                 name="tenant-management">

    <v-col cols="12" md="10" lg="6" offset-md="1" offset-lg="3">
      <v-row>
        <v-col cols="12">
          <v-card :color="$vuetify.breakpoint.xsOnly ? 'primary darken-1' : 'white'" :class="$vuetify.breakpoint.xsOnly ? 'white--text' : ''">
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div>
                <v-avatar :size="$vuetify.breakpoint.xsOnly ? '68' : '48'" :color="tenant.enabled ? $randomizeColorFromString(tenant.id, 75, 70) : 'grey lighten-2'" class="tenant-avatar" :class="$vuetify.breakpoint.xsOnly ? 'mb-2' : 'mr-3'">
                  {{ tenant.name ? tenant.name.substring(0, 2) : '' }}
                </v-avatar>
              </div>
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">{{ tenant.name }}</h3>
                <div class="body-1 text-center text-sm-left"><span>{{ $t('tenants.statuses.' + tenant.status) }}</span></div>
              </div>
            </v-card-title>

            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
              <v-btn class="ma-1" :disabled="savingTenant" text :color="$vuetify.breakpoint.xsOnly ? 'primary lighten-3' : 'primary'" @click="openEditTenantDialog">{{ $t('tenants.edit_organization') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card>
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'" class="text-center text-sm-left">{{ $t('tenant_management.thank_you_page_management') }}</h3>
              </div>
            </v-card-title>

            <v-card-text>
              {{ $t('tenant_management.thank_you_page_guide') }}
            </v-card-text>

            <v-card-text>
              <v-form ref="form">
                <v-textarea rows="3" :label="$t('tenant_management.thank_you_page_text')"
                            :placeholder="$t('public_job_ads.apply_for_another_job')"
                            persistent-hint
                            :hint="$t('tenant_management.thank_you_page_text_guide')"
                            v-model="tenant.postApplicationGuideText"/>
                <v-checkbox :label="$t('tenant_management.show_post_application_btn')"
                            v-model="tenant.postApplicationButtonVisible" />
                <v-expand-transition>
                  <template v-if="tenant.postApplicationButtonVisible">
                    <div>
                      <v-text-field :label="$t('tenant_management.back_to_list_btn_text')"
                                    :placeholder="$t('public_job_ads.move_back_to_list')"
                                    v-model="tenant.postApplicationButtonText" />
                      <v-text-field :label="$t('tenant_management.back_to_list_btn_url')"
                                    :hint="$t('tenant_management.back_to_list_btn_hint')"
                                    persistent-hint
                                    v-model="tenant.postApplicationButtonRedirectUrl" />
                    </div>
                  </template>
                </v-expand-transition>
              </v-form>
            </v-card-text>

            <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'justify-center pt-0' : ''">
              <v-btn class="ma-1" :loading="savingTenant" text :color="$vuetify.breakpoint.xsOnly ? 'primary lighten-3' : 'primary'" @click="saveTenant">{{ $t('save') }}</v-btn>
              <v-tooltip bottom right>
                <template v-slot:activator="{on}">
                  <v-btn class="ma-1" :disabled="savingTenant"
                         text
                         v-on="on"
                         :color="$vuetify.breakpoint.xsOnly ? 'primary lighten-3' : 'primary'"
                         href="./jobs#/hae-paikkaa/0?preview=thanks"
                         target="_blank">{{ $t('preview') }}</v-btn>
                </template>
                {{ $t('tenant_management.thank_you_page_preview_help') }}
              </v-tooltip>

            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <tenant-details-dialog ref="tenantDetailsDialog" @on-dismiss="onTenantDetailsDialogDismiss" />

  </hb-basic-page>
</template>

<script>
  import TenantDetailsDialog from "../../components/Tenant/TenantManagement/TenantDetailsDialog";
  import tenantApi from "../../api/tenant";

  export default {
    name: "TenantManagementView",
    components: {
      TenantDetailsDialog: TenantDetailsDialog,
    },
    data() {
      return {
        loading: true,
        subtleLoading: false,
        savingTenant: false,
        tenant: {},
      }
    },
    methods: {
      openEditTenantDialog() {
        this.$refs.tenantDetailsDialog.open(this.tenant);
      },
      onTenantDetailsDialogDismiss() {
        this.getTenant();
      },
      async getTenant() {
        if (this.tenant.id) {
          this.subtleLoading = true;
        } else {
          this.loading = true;
        }
        try {
          this.tenant = await tenantApi.getCurrentTenant();
        } catch (error) {
          this.$handleApiError(error);
        }
        this.loading = false;
        this.subtleLoading = false;
      },
      async saveTenant() {
        this.savingTenant = true;
        this.subtleLoading = true;
        try {
          this.tenant = await tenantApi.updateCurrentTenant(this.tenant);
        } catch (e) {
          this.$handleApiError(e);
        }
        this.savingTenant = false;
        this.subtleLoading = false;
      }
    },
    mounted() {
      this.getTenant();
    },
  }
</script>

<style lang="scss">
  .user-avatar {
    overflow: hidden;
  }

  .users-header {
    .v-list__tile {
      height: 30px;
    }
  }
</style>
