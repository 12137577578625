<template>
    <v-list class="py-0">
      <v-btn color="primary" small class="ma-3" outlined text @click="addApplication" :loading="loading">
        <v-icon left>mdi-plus</v-icon>
        {{$t('applicant.add_application')}}
      </v-btn>
      <v-divider></v-divider>
      <template v-for="(app, i) in applications">
        <v-list-item
          :dense="$vuetify.breakpoint.smAndDown"
          :key="app.id"
          @click="$router.push({name: 'single_application', params: { id: app.jobAd.id, applicationId: app.id}})">
          <v-list-item-content>
            <v-list-item-title>
              <span>{{ app.jobAd.name }}</span>
              <span v-if="app.jobAd.customers && app.jobAd.customers.length">, {{ app.jobAd.customers[0].name }}</span>
              <v-chip small class="ml-2" label dark :color="isJobAdActive(app.jobAd.searchEndDate) ? 'green' : 'red'">
                {{ $t('job_ads.states.' + app.jobAd.state) }}
              </v-chip>
            </v-list-item-title>
            <v-list-item-subtitle v-if="$t(`application.result.${app.result}`)">
              <span>{{ $t('applicants.application_result') }}: </span>
              <hb-status-chip class="white--text" :value="app.result" type="result" />
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action-text>
            {{ $formatDateNoTime(app.created) }}
          </v-list-item-action-text>
        </v-list-item>
        <v-divider v-if="i < applications.length - 1" :key="`${app.id}_divider`"></v-divider>
      </template>
      <add-or-edit-application-dialog
        ref="addOrEditApplicationDialog"
        :job-ads="jobAds"
        @updated="$emit('updated')"
      />
    </v-list>
</template>

<script>
import HbStatusChip from "@/components/Tenant/HbStatusChip.vue";
import moment from "moment";
import AddOrEditApplicationDialog from '@/components/Tenant/Applicant/AddOrEditApplicationDialog';

const STATE_HAUSSA = 1;
const STATE_HAASTATTELUT = 3;
const STATE_HIDDEN = 4;

export default {
  name: "ApplicantApplications",
  components: { AddOrEditApplicationDialog, HbStatusChip},
  props: ['applicant', 'applications'],
  data() {
    return {
      loading: false,
      jobAds: [],
      jobAdFilter: {
        states: [STATE_HAUSSA, STATE_HAASTATTELUT, STATE_HIDDEN],
        searchString: null,
      },
      jobAdOptions: {
        page: 0,
        itemsPerPage: -1,
      },
    }
  },
  methods: {
    addApplication() {
      this.$refs.addOrEditApplicationDialog.open(this.applicant);
    },
    isJobAdActive(searchEndDate) {
      return moment(searchEndDate).isAfter(moment());
    },
    async reloadJobAds() {
      try {
        this.jobAds = (await this.$tenantApi.getJobAdsPaged(this.jobAdFilter, this.jobAdOptions)).content;
      } catch (e) {
        this.$handleApiError(e);
      }
    },
  },
  async mounted() {
    await this.reloadJobAds();
  },
}
</script>

<style scoped>

</style>
