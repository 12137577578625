<template>
  <hb-basic-page :title="$t('applicants.title')"
                 :loading="loading"
                 :subtle-loading="applicantsLoading"
                 :fill-height="loading"
                 :no-gutters="true"
                 name="applicants-view">
    <v-fab-transition v-if="!loading && applicants.length > 0">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="primary"
            :loading="generatingExcel"
            fab dark bottom right fixed
            @click="exportExcel"
            style="margin-bottom: 50px"
          >
            <v-icon>download</v-icon>
          </v-btn>
        </template>
        {{ $t('applicants.download_excel') }}
      </v-tooltip>
    </v-fab-transition>
    <v-col cols="12">
      <v-row justify="center">
        <v-col cols="12" sm="10" xl="6">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="searchPhrase" :label="$t('applicants.search')" hide-details outlined clearable>
                    <template v-slot:append>
                      <v-icon>search</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-expansion-panels flat>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-0">
                        <v-badge color="red" inline :value="selectedLicences && selectedLicences.length" :content="selectedLicences.length" class="justify-start mt-0 pt-1">
                          <span class="title mr-1">{{ $t('applicants.cards_and_permits') }}</span>
                        </v-badge>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mx-n5">
                        <v-row no-gutters>
                          <v-col cols="12">
                            <hb-licence-selector ref="lisenssit" :selected-items="selectedLicences" @change="handleLicensesChange"></hb-licence-selector>
                          </v-col>
                        </v-row>
                        <v-row v-if="hasFilters">
                          <v-col cols="12">
                            <v-btn color="primary" block small outlined text @click="clearFilterSelections">{{ $t('applicants.remove_selections') }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="px-0">
                        <v-badge color="red" inline :value="selectedIndustries.length" :content="selectedIndustries.length" class="justify-start mt-0 pt-1">
                          <span class="title mr-1">{{ $t('applicants.education_and_experience') }}</span>
                        </v-badge>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="mx-n5">
                        <v-row no-gutters>
                          <v-col cols="12">
                            <hb-education-selector
                              :selected-items="selectedEducation"
                              @change="handleEducationChange"
                              :all-industries="allIndustries" />
                            <hb-experience-selector
                              :selected-items="selectedExperience"
                              @change="handleExperienceChange"
                              :all-industries="allIndustries" />
                          </v-col>
                        </v-row>
                        <v-row v-if="hasFilters">
                          <v-col cols="12">
                            <v-btn color="primary" block small outlined text @click="clearFilterSelections">{{ $t('applicants.remove_selections') }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="flex-wrap" justify="center">
        <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12">
          <template>
            <v-btn
              @click="sendEmailToSelected"
              :loading="sendingEmail"
              small
              class="mb-3"
              :disabled="selectedCount === 0">
              {{ sendEmailToSelectedText }}
            </v-btn>
            <v-btn
              @click="selectAll"
              :loading="sendingEmail"
              small
              class="mb-3 ml-3">
              {{ $t('applicants.select_all') }}
            </v-btn>
            <v-btn
              @click="selected={}"
              :loading="sendingEmail"
              small
              class="mb-3 ml-3"
              :disabled="selectedCount === 0">
              {{ $t('applicants.clear_selected') }}
            </v-btn>
            <v-data-table :headers="headers"
                          :items="applicants"
                          :server-items-length="totalApplicants"
                          :page="pageNo"
                          :items-per-page="pageSize"
                          :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }"
                          :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc"
                          :must-sort="true"
                          @update:sort-by="reloadApplicants"
                          @update:sort-desc="reloadApplicants"
                          @pagination="paginate"
                          :loading="applicantsLoading"
                          :no-data-text="$t('applicants.no_applicants')"
                          :no-results-text="$t('applicants.no_applicants')">
              <template v-slot:item.check="{ item }">
                <v-checkbox v-model="selected[item.email]"/>
              </template>
              <template v-slot:item.name="{ item }">
                {{ item.firstName }} {{ item.lastName }}
                <div class="caption"><a :href="`tel:${item.phone}`">{{ item.phone }}</a></div>
                <div class="caption"><a :href="`mailto:${item.email}`">{{ item.email }}</a></div>
              </template>
              <template v-slot:item.appliedJobAds="{ item }">
                <template v-for="(application, index) in item.appliedJobAds">
                  <v-list-item :key="index">
                    <v-list-item
                      :to="linkToJobApplications(application)">
                      {{ appliedJobAdName(application) }}
                      </v-list-item>
                    <v-list-item
                      :to="{name: 'single_application', params: { id: application.jobAd.id, applicationId: application.applicationId } }">
                      {{ appliedJobAdStatus(application) }}
                    </v-list-item>
                  </v-list-item>
                </template>
              </template>
              <template v-slot:item.background="{ item }">
                <div>
                  <div v-if="item.experience && item.experience.length" class="my-2">
                    <div class="font-weight-bold">{{ $t('experience.title') }}:</div>
                    <div class="caption" style="line-height: 14px;">
                      <template v-for="experience in item.experience">
                        <div :key="experience.id">
                          {{ experience.industry ? experience.industry.name : '' }}: {{ experience.years === 0 ? $t(`experience.none`) : experience.years > 5 ? $t(`experience.over_x_years`, {x: 5}) : `${ experience.years } ${$tc('experience.year', experience.years)}` }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div v-if="item.education && item.education.length" class="my-2">
                    <div class="font-weight-bold">{{ $t('education.title') }}:</div>
                    <div class="caption" style="line-height: 14px;">
                      <template v-for="education in item.education">
                        <div :key="education.id">
                          {{ education.industry ? education.industry.name + ': ' : '' }} {{ education.educationLevel ? $t('education.education_levels.' + education.educationLevel) : '' }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:item.updated="{ item }">
                <span class="caption">{{ $formatDateNoTime(item.updated) }}</span>
              </template>
              <template v-slot:item.created="{ item }">
                <span class="caption">{{ $formatDateNoTime(item.created) }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn color="primary" small outlined text :to="{name: 'single_applicant', params: { id: item.id } }">
                  <v-icon left>mdi-account-circle</v-icon>
                  {{$t('applicants.goto_profile')}}
                </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-col>
        <!-- smAndDown -->
        <v-col v-if="$vuetify.breakpoint.smAndDown" cols="12">
          <v-list dense two-line>
            <template v-for="(applicant, index) in applicants">
              <v-list-item
                :key="`applicant_mobile_list_key_${applicant.id}`"
                :to="{name: 'single_applicant', params: { id: applicant.id } }">
                <v-list-item-avatar>
                  <v-icon x-large>mdi-account-circle</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>
                    {{ applicant.firstName }} {{ applicant.lastName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ applicant.email }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    {{ applicant.phone }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn icon :to="{name: 'single_applicant', params: { id: applicant.id } }">
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-divider v-if="applicants.length > index + 1" :key="`applicant_mobile_list_divider_key_${applicant.id}`"></v-divider>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-col>

    <send-email-dialog ref="sendEmailDialog" />
    <failed-email-dialog ref="failedEmailDialog" />

  </hb-basic-page>
</template>
<script>
import tenantApi from '@/api/tenant';
import { debounce } from 'lodash';
import HbLicenceSelector from '@/components/Tenant/HbLicenceSelector.vue';
import HbEducationSelector from '@/components/Tenant/HbEducationSelector';
import HbExperienceSelector from '@/components/Tenant/HbExperienceSelector';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import SendEmailDialog from '@/components/SendEmailDialog';
import FailedEmailDialog from "@/components/Tenant/FailedEmailDialog.vue";

export default {
  name: "ApplicantsView",
  components: {FailedEmailDialog, SendEmailDialog, HbEducationSelector, HbExperienceSelector, HbLicenceSelector },
  data() {
    return {
      sendingEmail: false,
      loading: true,
      subtleLoading: false,
      applicantsLoading: false,
      generatingExcel: false,
      searchPhrase: "",
      innerSearchPhrase: "",
      showLicencesSelector: false,
      selectedLicences: [],
      selectedEducation: [],
      selectedExperience: [],
      applicants: [],
      totalApplicants: 0,
      selectedApplicant: null,
      contextMenu: [],
      pageSize: 50,
      pageNo: 1,
      sortBy: 'updated',
      sortDesc: true,
      options: {
        education: [],
        experience: [],
        licenses: [],
      },
      headers: [
        { text: '', value: 'check', sortable: false },
        { text: this.$t('applicants.name'), value: 'name', sortable: false },
        { text: this.$t('applicants.applications'), value: 'appliedJobAds', sortable: false },
        { text: this.$t('applicants.industries'), value: 'background', sortable: false },
        { text: this.$t('applicants.updated'), value: 'updated' },
        { text: this.$t('applicants.created'), value: 'created' },
        { text: '', value: 'actions', align: 'right', sortable: false },
      ],
      allIndustries: [],
      selected: {},
    }
  },
  methods: {
    selectAll() {
      this.applicants.forEach(a => {
        this.$set(this.selected, a.email, true);
      });
    },
    async sendEmailToSelected() {
      this.sendingEmail = true;
      try {
        const emails = Object.entries(this.selected).filter(e => e[1]).map(e => e[0]);
        const [confirm, data] = await this.$refs.sendEmailDialog.open({}, emails);
        if (confirm) {
          const response = await tenantApi.sendEmail(data);

          if (response && response.errorCount > 0) {
            if (response.successfulCommunications && response.successfulCommunications.length > 0) {
              this.$showWarningNotification(this.$t('email.send_failed_partial'));
            } else {
              this.$showErrorNotification(this.$t('email.send_failed'));
            }
            this.showEmailFailedDialog(response);
          } else {
            this.$showSuccessNotification(this.$t('email.send_success'));
          }
        }
        this.selected = {};
      } catch (e) {
        this.$handleApiError(e);
      }
      this.sendingEmail = false;
    },
    showEmailFailedDialog(communicationResponse) {
      this.$refs.failedEmailDialog.open(communicationResponse);
    },
    linkToJobApplications(application) {
      return {
        name: 'job_ad',
        params: { id: application.jobAd.id },
        query: { tab: 1 },
      };
    },
    appliedJobAdName(application) {
      return application.jobAd.name;
    },
    appliedJobAdStatus(application) {
      if (application.result !== 'NONE') {
        return `${this.$t(`application.result.${application.result}`)}`;
      } else {
        return `${this.$t(`application.status.${application.status}`)}`;
      }
    },
    exportExcel() {
      this.generatingExcel = true;

      const wb = XLSX.utils.book_new();
      const ws_name = this.$t('applicants.excel_filename');

      const headers = [
        this.$t('applicants.name'),
        this.$t('applicants.email'),
        this.$t('applicants.phone'),
        this.$t('applicants.city'),
        this.$t('applicants.applications'),
        this.$t('applicants.experience'),
        this.$t('applicants.education'),
        this.$t('applicants.updated'),
        this.$t('applicants.created'),
      ];

      /* make worksheet */
      const ws_data = [
        headers,
        ...this.exportApplicants,
      ];
      const ws = XLSX.utils.aoa_to_sheet(ws_data);

      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      const wbOut = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });

      function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
      }

      const fileName = `${this.$t('applicants.excel_filename')}.xlsx`;

      saveAs(new Blob([s2ab(wbOut)], { type: "application/octet-stream" }), fileName);

      this.generatingExcel = false;
    },
    async reloadIndustries() {
      this.loading = true;
      try {
        this.allIndustries = await this.$tenantApi.getIndustries();
      } catch (e) {
        this.$handleApiError(e);
      }
      this.loading = false;
    },
    async paginate(p) {
      this.pageNo = p.page;
      this.pageSize = p.itemsPerPage;
      await this.reloadApplicants();
    },
    async reloadApplicants() {
      this.applicantsLoading = true;
      try {
        this.options.licenses = [
          ...this.selectedLicences,
        ];
        if (this.selectedExperience.length > 0) {
          this.options.experience = [
            ...this.selectedExperience,
          ];
        } else {
          this.options.experience = [];
        }
        if (this.selectedEducation.length > 0) {
          this.options.education = [
            ...this.selectedEducation,
          ];
        } else {
          this.options.education = [];
        }

        const response = await tenantApi.searchApplicants({
          pageNo: !this.$vuetify.breakpoint.smAndDown ? this.pageNo : 1,
          pageSize: !this.$vuetify.breakpoint.smAndDown ? this.pageSize : -1,
          search: this.innerSearchPhrase,
          experience: this.options.experience,
          education: this.options.education,
          licenses: this.options.licenses,
          sortBy: this.sortBy,
          descending: this.sortDesc,
        });
        this.applicants = response.content;
        this.totalApplicants = response.totalElements;
      } catch (error) {
        this.$handleApiError(error);
      }
      this.applicantsLoading = false;
    },
    handleSearch(e) {
      this.innerSearchPhrase = e;
      this.reloadApplicants();
    },
    handleLicensesChange(licenses) {
      this.selectedLicences = licenses;
    },
    handleEducationChange(industries) {
      this.selectedEducation = industries;
    },
    handleExperienceChange(industries) {
      this.selectedExperience = industries;
    },
    clearFilterSelections() {
      this.selectedLicences = [];
      this.reloadApplicants();
    },
  },
  computed: {
    sendEmailToSelectedText() {
      return `${this.$t('applicants.send_email_to_selected')} (${this.selectedCount} ${this.$t('applicants.selected')})`;
    },
    selectedCount() {
      return Object.values(this.selected).filter(v => v).length;
    },
    exportApplicants() {
      return this.applicants.map(a => {
        const experience = a.experience.map(e => {
          const years = e.years > 5 ? '>5' : `${e.years}`;
          return `${e.industry.name} (${years})`
        }).join(', ');

        const education = a.education.map(e => {
          const industry = e.industry ? e.industry.name + ': ' : '';
          const level = e.educationLevel ? this.$t('education.education_levels.' + e.educationLevel) : '';
          return `${industry}${level}`
        }).join(', ');

        return [
          `${a.firstName} ${a.lastName}`,
          a.email || '',
          a.phone || '',
          a.city || '',
          a.appliedJobAds.map(app => app.jobAd.name).join(', '),
          experience,
          education,
          this.$formatDateNoTime(a.updated),
          this.$formatDateNoTime(a.created),
        ];
      });
    },
    selectedIndustries() {
      return [...this.selectedEducation, ...this.selectedExperience];
    },
    hasFilters() {
      return (this.selectedLicences && this.selectedLicences.length) || (this.valitutAjokorttiLuokat && this.valitutAjokorttiLuokat.length);
    },
  },
  watch: {
    searchPhrase(to) {
      this.debouncedSearch(to);
    },
    selectedLicences() {
      this.debouncedApplicants();
    },
    selectedEducation() {
      this.debouncedApplicants();
    },
    selectedExperience() {
      this.debouncedApplicants();
    },
  },
  async mounted() {
    await this.reloadApplicants();
    await this.reloadIndustries();
    this.loading = false;
    this.selected = {};
  },
  created() {
    this.debouncedSearch = debounce(this.handleSearch, 450);
    this.debouncedApplicants = debounce(this.reloadApplicants, 600);
  }
}
</script>

<style scoped>

</style>
