<template>
  <v-col cols="12" class="applicants pt-0">
    <v-row class="justify-center" v-if="job.industry && alternativeIndustryNames">
      <v-col cols="12" sm="12" md="10" xl="8">
        <v-card>
          <v-list-item v-if="job.industry">
            <v-list-item-subtitle class="subtitle">{{ $t('job_ads.detail.industry') }}</v-list-item-subtitle>
            <v-list-item-title>{{ job.industry.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="alternativeIndustryNames">
            <v-list-item-subtitle class="subtitle">{{ $t('job_ads.detail.alternative_industries') }}</v-list-item-subtitle>
            <v-list-item-title>{{ alternativeIndustryNames }}</v-list-item-title>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="applications-search justify-center">
      <v-col cols="12" sm="12" md="10" xl="8">
        <v-card>
          <v-card-text class="py-0">
            <!--<v-row>
              <v-col cols="12">
                <v-btn v-if="!isSearchVisible && !$vuetify.breakpoint.mdAndUp" @click="toggleSearch" text color="primary" class="mr-3">
                  <v-icon>search</v-icon>
                  {{ $t('job_ad.search_applications') }}
                </v-btn>
                <v-text-field  v-if="isSearchVisible || $vuetify.breakpoint.mdAndUp"  v-model="searchPhrase" :label="$t('job_ad.search_applications')" hide-details outlined clearable>
                  <template v-slot:append>
                    <v-icon>search</v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>-->

            <v-row class="filtterit justify-center">
              <v-col cols="12">
                <v-expansion-panels flat class="elevation-0">
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-0">
                      <v-badge color="red" inline :value="suodatusLuvilla && suodatusLuvilla.length" :content="suodatusLuvilla.length" class="justify-start mt-0 pt-1">
                        <span class="title mr-1">{{ $t('applicants.cards_and_permits') }}</span>
                      </v-badge>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mx-n5">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <hb-licence-selector :selected-items.sync="suodatusLuvilla" @change="handleSuodatusLuvillaChanged"></hb-licence-selector>
                        </v-col>
                        <v-col cols="12" class="mt-3" v-if="suodatusLuvilla && suodatusLuvilla.length">
                          <v-btn color="primary" block small outlined text @click="suodatusLuvilla = []">{{ $t('applicants.remove_selections') }}</v-btn>
                        </v-col>
                        <v-col cols="12" class="mt-3" v-else>
                          <v-btn color="primary" block small outlined text @click="suodatusLuvilla = job.licenses.map(l => l.code)">{{ $t('applicants.filter_by_required_licenses') }}</v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="px-0">
                      <v-badge color="red" inline :value="selectedIndustries && selectedIndustries.length" :content="selectedIndustries.length" class="justify-start mt-0 pt-1">
                        <span class="title mr-1">{{ $t('applicants.education_and_experience') }}</span>
                      </v-badge>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="mx-n5">
                      <v-row no-gutters>
                        <v-col cols="12">
                          <hb-education-selector
                            :selected-items="selectedEducation"
                            @change="handleEducationChange"
                            :all-industries="allIndustries" />
                          <hb-experience-selector
                            :selected-items="selectedExperience"
                            @change="handleExperienceChange"
                            :all-industries="allIndustries" />
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="headline">
            {{ $t('job_ad.suggestions') }}
          </v-card-title>
          <v-card-text>
            <v-btn
              @click="sendEmailToSelected"
              :loading="sendingEmail"
              small
              class="mb-3"
              :disabled="selectedCount === 0">
              {{ sendEmailToSelectedText }}
            </v-btn>
            <v-btn
              @click="selectAll"
              :loading="sendingEmail"
              small
              class="mb-3 ml-3">
              {{ $t('applicants.select_all') }}
            </v-btn>
            <v-btn
              @click="selected={}"
              :loading="sendingEmail"
              small
              class="mb-3 ml-3"
              :disabled="selectedCount === 0">
              {{ $t('applicants.clear_selected') }}
            </v-btn>
            <v-data-table
              :headers="headers"
              :items="applicants"
              :loading="applicantsLoading"
              :server-items-length="totalApplicants"
              :page="pageNo"
              :items-per-page="pageSize"
              :footer-props="{ 'items-per-page-options': [10, 25, 50, -1]}"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :must-sort="true"
              @update:sort-by="debouncedReloadApplicants"
              @update:sort-desc="debouncedReloadApplicants"
              @pagination="paginate"
              class="elevation-1">
              <template v-slot:item.check="{ item }">
                <v-checkbox v-model="selected[item.email]"/>
              </template>
              <template v-slot:item.applicant="{ item }">
                <strong>{{ item.firstName }} {{ item.lastName }}</strong><br>
                {{ item.phone }}<br>
                {{ item.email }}
              </template>
              <template v-slot:item.background="{ item }">
                <div>
                  <div v-if="item.experience && item.experience.length > 0" class="my-2">
                    <div class="font-weight-bold">{{ $t('experience.title') }}:</div>
                    <div class="caption" style="line-height: 14px;">
                      <template v-for="experience in item.experience">
                        <div :key="experience.id">
                          {{ experience.industry ? experience.industry.name : '' }}: {{ experience.years === 0 ? $t(`experience.none`) : experience.years > 5 ? $t(`experience.over_x_years`, {x: 5}) : `${ experience.years } ${$tc('experience.year', experience.years)}` }}
                        </div>
                      </template>
                    </div>
                  </div>
                  <div v-if="item.education && item.education.length > 0" class="my-2">
                    <div class="font-weight-bold">{{ $t('education.title') }}:</div>
                    <div class="caption" style="line-height: 14px;">
                      <template v-for="education in item.education">
                        <div :key="education.id">
                          {{ education.industry ? education.industry.name + ': ' : '' }} {{ education.educationLevel ? $t('education.education_levels.' + education.educationLevel) : '' }}
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:item.link="{ item }">
                <v-btn color="primary" small outlined text :to="{name: 'single_applicant', params: { id: item.id } }">
                  <v-icon left>mdi-account-circle</v-icon>
                  {{$t('applicants.goto_profile')}}
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <send-email-dialog ref="sendEmailDialog" />
    <failed-email-dialog ref="failedEmailDialog" />

  </v-col>
</template>

<script>
import tenantApi from '@/api/tenant';
import SendEmailDialog from '@/components/SendEmailDialog';
import FailedEmailDialog from "@/components/Tenant/FailedEmailDialog.vue";
import {debounce} from "lodash";
import HbEducationSelector from "@/components/Tenant/HbEducationSelector.vue";
import HbExperienceSelector from "@/components/Tenant/HbExperienceSelector.vue";
import HbLicenceSelector from "@/components/Tenant/HbLicenceSelector.vue";

export default {
  name: 'SingleJobAdSuggestions',
  components: {HbLicenceSelector, HbExperienceSelector, HbEducationSelector, FailedEmailDialog, SendEmailDialog},
  props: {
    job: {
      type: Object,
      default: () => ({}),
    },
    allIndustries: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      loading: true,
      applicantsLoading: false,
      applicants: [],
      totalApplicants: 0,
      pageSize: 50,
      pageNo: 1,
      selectedLicenses: [],
      selectedEducation: [],
      selectedExperience: [],
      valitutAjokorttiLuokat: [],
      suodatusLuvilla: [],
      questionsToFilterBy: [],
      questionAnswers: {},
      sortBy: 'updated',
      sortDesc: true,
      headers: [
        { text: '', value: 'check', sortable: false },
        { text: this.$t('job_ad.name_and_contact'), value: 'applicant', cols: '2' },
        { text: this.$t('applicants.industries'), value: 'background', sortable: false },
        { text: '', value: 'link', cols: '1' },
      ],
      sendingEmail: false,
      selected: {},
      failedCommunications: [],
    };
  },
  computed: {
    sendEmailToSelectedText() {
      return `${this.$t('applicants.send_email_to_selected')} (${this.selectedCount} ${this.$t('applicants.selected')})`;
    },
    selectedCount() {
      return Object.values(this.selected).filter(v => v).length;
    },
    alternativeIndustryNames() {
      return this.job.alternativeIndustries.map(i => i.name).join(', ');
    },
    selectedIndustries() {
      return [...this.selectedEducation, ...this.selectedExperience];
    },
  },
  methods: {
    handleSuodatusLuvillaChanged(luvat) {
      this.suodatusLuvilla = [ ...new Set(luvat)];
    },
    handleEducationChange(industries) {
      this.selectedEducation = industries;
    },
    handleExperienceChange(industries) {
      this.selectedExperience = industries;
    },
    selectAll() {
      this.applicants.forEach(a => {
        this.$set(this.selected, a.email, true);
      });
    },
    async sendEmailToSelected() {
      this.sendingEmail = true;
      try {
        const emails = Object.entries(this.selected).filter(e => e[1]).map(e => e[0]);
        const [confirm, data] = await this.$refs.sendEmailDialog.open({}, emails);
        if (confirm) {
          const response = await tenantApi.sendEmail(data);

          if (response && response.errorCount > 0) {
            if (response.successfulCommunications && response.successfulCommunications.length > 0) {
              this.$showWarningNotification(this.$t('email.send_failed_partial'));
            } else {
              this.$showErrorNotification(this.$t('email.send_failed'));
            }
            this.showEmailFailedDialog(response);
          } else {
            this.$showSuccessNotification(this.$t('email.send_success'));
          }
        }
        this.selected = {};
      } catch (e) {
        this.$handleApiError(e);
      }
      this.sendingEmail = false;
    },
    showEmailFailedDialog(communicationResponse) {
      this.$refs.failedEmailDialog.open(communicationResponse);
    },
    async paginate(p) {
      this.pageNo = p.page;
      this.pageSize = p.itemsPerPage;
      await this.debouncedReloadApplicants();
    },
    async reloadApplicants() {
      this.applicantsLoading = true;
      try {
        const options = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          sortBy: this.sortBy,
          descending: this.sortDesc,
          jobAdId: this.job.id,
          licenses: this.suodatusLuvilla,
          experience: this.selectedExperience,
          education: this.selectedEducation,
        };

        const response = await tenantApi.getSuggestedApplicants(options);

        localStorage.setItem(`suggestionsSearchOptions_${this.$tenant}`, JSON.stringify({
          ...options
        }));

        this.applicants = response.content;
        this.totalApplicants = response.totalElements;
      } catch (error) {
        this.$handleApiError(error);
      }
      this.applicantsLoading = false;
    },
  },
  watch: {
    suodatusLuvilla() {
      this.debouncedReloadApplicants();
    },
    selectedEducation() {
      this.debouncedReloadApplicants();
    },
    selectedExperience() {
      this.debouncedReloadApplicants();
    },
  },
  async mounted() {
    const optionsJson = localStorage.getItem(`suggestionsSearchOptions_${this.$tenant}`);
    if (optionsJson) {
      const options = JSON.parse(optionsJson);
      if (options.jobAdId === this.job.id) {
        this.suodatusLuvilla = options.licenses;
        this.selectedExperience = options.experience;
        this.selectedEducation = options.education;
      }
    }
    await this.reloadApplicants();
    this.loading = false;
  },
  created() {
    this.debouncedReloadApplicants = debounce(this.reloadApplicants, 300);
  },
}
</script>

<style scoped>
.applicants {
  width: 100vw;
}
.application-row-darken {
  background-color: #f5f5f5;
}
.subtitle {
  max-width: 180px;
}
</style>
