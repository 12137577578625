<template>
  <hb-basic-page :title="$t('statistics.title')"
                 :loading="loading"
                 :subtle-loading="subtleLoading"
                 :no-gutters="true"
                 name="tenant-management">

    <v-col cols="12" md="10" lg="6" offset-md="1" offset-lg="3">
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title primary-title :class="$vuetify.breakpoint.xsOnly ? 'column justify-center' : ''">
              <div :style="$vuetify.breakpoint.xsOnly ? 'width: 100%' : ''">
                <h3 :class="$vuetify.breakpoint.xsOnly ? 'title' : 'headline'">{{ $t('statistics.applicants_statistics') }}</h3>
              </div>
            </v-card-title>

            <v-card-text>
              <template v-for="item in parsedStatistics">
                <v-row :key="item.tenant">
                  <v-col cols="12" class="text-h5">{{ item.tenant }}</v-col>
                  <v-col cols="12" v-if="Object.keys(item.whereTexts).length > 0">
                    <div class="subtitle-1">{{ $t('statistics.where_applicant_found_ad') }}</div>
                    <v-list>
                      <template v-for="key in Object.keys(item.whereTexts)">
                        <v-list-item :key="key" v-if="key === 'Jokin muu'"  class="px-0">
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ key }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="caption">
                              {{ Object.keys(item.whereTexts[key].descriptions).join(', ') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title class="text-right">{{ item.whereTexts[key].total }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else :key="key" class="px-0">
                          <v-list-item-content>
                            <v-list-item-subtitle>{{ key }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title class="text-right">{{ item.whereTexts[key] }}</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="key + '_divider'"></v-divider>
                      </template>
                    </v-list>
                  </v-col>
                  <v-col cols="12" v-else>
                    {{ $t('statistics.no_statistics_for_tenant') }}
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>


  </hb-basic-page>
</template>

<script>
import adminApi from "@/api/admin";

export default {
  name: "StatisticsView",
  data() {
    return {
      loading: true,
      subtleLoading: false,
      statistics: [],
    }
  },
  methods: {
    async reloadData() {
      try {
        this.statistics = await adminApi.getStatistics();
      } catch(e) {
        this.$handleApiError(e);
      }
    }
  },
  computed: {
    parsedStatistics() {
      return this.statistics.map(({ tenant, whereTexts }) => {
        const grouped = {};
        let jokinMuuTotal = 0;
        let descriptions = {};

        Object.entries(whereTexts).forEach(([key, value]) => {
          if (key === "") return;
          if (key.startsWith("Jokin muu:")) {
            const [, description] = key.split(": ");
            jokinMuuTotal += value;
            descriptions[description] = (descriptions[description] || 0) + value;
          } else {
            grouped[key] = value;
          }
        });

        if (jokinMuuTotal > 0) {
          grouped["Jokin muu"] = { total: jokinMuuTotal, descriptions };
        }

        return { tenant: tenant.name, whereTexts: grouped };
      });
    }
  },
  async mounted() {
    await this.reloadData();
    this.loading = false;
  },
}
</script>

<style lang="scss">
.user-avatar {
  overflow: hidden;
}

.users-header {
  .v-list__tile {
    height: 30px;
  }
}
</style>
